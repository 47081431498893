var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Clients")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un client")]),_c('h4',[_vm._v(" Si vous souhaitez ajouter un client professionnel ou particulier, veuillez suivre les étapes ci-dessous : ")]),_vm._m(0),_c('h4',[_vm._v("Client Particulier")]),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499355_AjouterUnClientParticulier.mp4',"controls":""}})]),_c('h4',[_vm._v("Client Professionnel")]),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499334_AjouterUnClientPro.mp4',"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier un client")]),_c('h4',[_vm._v(" Si vous souhaitez modifier les informations d'un client, veuillez suivre les étapes ci-dessous : ")]),_vm._m(1),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499447_ModifierUnClient.mp4',"controls":""}})]),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Supprimer un client")]),_c('h4',[_vm._v(" Si vous souhaitez supprimer un article, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682596714_Suppression d'un client.mp4`,"controls":""}})]),_c('p',[_vm._v(" Attention : La suppression d'un client est une action définitive et irréversible. Assurez-vous de bien vouloir supprimer le client avant de valider l'action. ")]),_c('h3',{attrs:{"id":"recherche"}},[_vm._v("Rechercher les clients")]),_c('h4',[_vm._v(" Si vous souhaitez rechercher un client par nom, prénom ou e-mail, veuillez suivre les étapes ci-dessous : ")]),_vm._m(3),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499372_RechercheClient.mp4',"controls":""}})]),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes avec un client, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accédez à la page des clients en cliquant sur le bouton \"Clients\". ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouveau client\".")]),_c('li',[_vm._v("Choisissez le type de client \"Professionnel\" ou \"Particulier\".")]),_c('li',[_vm._v(" Remplissez le formulaire d'ajout (*certains champs sont obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour ajouter votre client avec succès. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accédez à la page des clients en cliquant sur le bouton \"Clients\". ")]),_c('li',[_vm._v(" Recherchez le client que vous souhaitez modifier en utilisant le champ de recherche. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Modifier\" en face du client que vous souhaitez modifier. ")]),_c('li',[_vm._v(" Modifiez les informations que vous souhaitez changer dans le formulaire d'édition (*certains champs sont obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour sauvegarder les modifications. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accédez à la page des clients en cliquant sur le bouton \"Clients\". ")]),_c('li',[_vm._v(" Recherchez le client que vous souhaitez supprimer en utilisant le champ de recherche. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Supprimer\" en face du client que vous souhaitez supprimer. ")]),_c('li',[_vm._v(" Confirmez la suppression en cliquant sur le bouton \"supprimer\" dans la fenêtre de confirmation. ")]),_c('li',[_vm._v("Le client sera supprimé de votre liste.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Accédez à la page des clients en cliquant sur le bouton \"Clients\". ")]),_c('li',[_vm._v("Cliquez sur le champ de recherche.")]),_c('li',[_vm._v(" Entrez le nom, le prénom ou l'e-mail du client que vous recherchez. ")]),_c('li',[_vm._v(" Cliquez sur le bouton de recherche pour afficher les résultats correspondants. ")])])
}]

export { render, staticRenderFns }